import { differenceInMonths, differenceInDays } from 'date-fns';

/**
    * @description: 计算时间差值
    * @param {*} startDate 小时间
    * @param {*} endDate 大时间
    * @param {*} type 默认 month
    * @return {*} type为month时返回月份差值，否则返回时间差值，格式为多少年多少月多少日
    */
export function calculateMonthsAndDays(startDate, endDate, type = 'month') {
  const start = new Date(startDate);
  const end = new Date(endDate);
  // 计算两个日期之间的月份差异
  const monthsDiff = differenceInMonths(end, start);
  // 计算两个日期之间的天数差异
  const daysDiff = differenceInDays(end, start);
  // 计算年份和剩余的月份
  const years = Math.floor(monthsDiff / 12);
  const remainingMonths = monthsDiff % 12;
  const remainingDays = daysDiff % 30;
  let result = '';
  if (years > 0) {
    result += `${years}年`;
  }
  if (remainingMonths > 0) {
    result += `${remainingMonths}个月`;
  }
  if (remainingDays > 0) {
    result += `${remainingDays}天`;
  }
  let data = ''
  if (type === 'month') {
    data = Number(monthsDiff)
  } else {
    data = result
  }
  return data
}




/**
 * 获取uuid
 */
export function getUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
  return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}
/**
 * @description: 是否拥有全匹配权限
 * @param {*} arr 需要验证的数组
 * @return {*} 结果，true/false
 */
export function isAuths(arr) {
  let permissions = new Set(JSON.parse(sessionStorage.getItem('permissions') || '[]'))
  let isHas = true
  for (const item of arr) {
    if (!permissions.has(item)) {
      isHas = false
      break
    }
  }
  return isHas
}
/**
 * @description: 是否拥有部分权限
 * @param {*} arr 需要验证的数组
 * @return {*} 结果，一项满足返回true,否则fasle
 */
export function isAmongAuth(arr) {
  const storedPermissions = JSON.parse(sessionStorage.getItem('permissions') || '[]');
  return arr.some(permission => storedPermissions.includes(permission));
}
/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'parentId') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}
/**
 * 将价格转换为大写金额
 * @param {number} price - 需要转换的价格
 * @returns {array} - 包含转换后的价格和单位的数组
 */
export function unitChange(price) {
  let priceStr = price + ''
  let length = priceStr.split('.')[0].length
  if (length < 5) {
    return [price, '']
  } else if (length < 10) {
    return [(price / 10000).toFixed(1), '万']
  } else {
    return [(price / 100000000).toFixed(1), '亿']
  }
}

// 获取当前月份的最后一天
export function getMonthLastDay(timestamp) {

  let date = new Date(timestamp)
  let year = date.getFullYear()
  let month = date.getMonth() + 2
  let day = date.getDate()
  return +new Date(year + '-' + month + '-' + day) - 1
}
// 导出函数 getIntradayMonth，用于获取当天的月份
export function getIntradayMonth(type) {
  // 声明变量 time
  let time
  // 获取当前日期和时间
  let date = new Date()
  // 获取当前年份
  let year = date.getFullYear()
  // 获取当前月份（范围为0-11），加1转换为实际月份
  let month = date.getMonth() + 1
  // 获取当前日期，范围为1-31
  let strDate = date.getDate()
  // 如果月份小于10，则在月份前补0，使其为两位数
  if (month < 10) {
    month = '0' + month
  }
  // 如果日期小于10，则在日期前补0，使其为两位数
  if (strDate < 10) {
    strDate = '0' + strDate
  }
  // 如果 type 为真，则设置 time 为当前年份、月份、日期为0时的字符串表示
  // 否则，设置 time 为当前年份、月份、日期为23时59分59秒的字符串表示
  if (type) {
    time = `${year}-${month}-${strDate} 00:00:00`
  } else {
    time = `${year}-${month}-${strDate} 23:59:59`
  }
  // 返回时间字符串 time
  return time
}

/**
* 获取当月的第一天 最后一天 当天
* @param  // type 0 第一天; 1 最后一天; 不传 当天;
* @param  // isMonth 1 年-月; 不传 年-月-日;
*/
export function getCurMonthFirstOrLast(type = 2, isMonth) {
  let date = new Date;
  let y = date.getFullYear().toString();
  let m = (date.getMonth() + 1).toString().padStart(2, 0);
  let d = (['1', new Date(y, m, 0).getDate(), date.getDate()][type]).toString().padStart(2, 0);
  if (isMonth && isMonth === 1) {
    return [y, m].join("-");
  } else {
    return [y, m, d].join("-");
  }
}

/**
 * 时间戳时间转字符串格式
 * @params {*} val
 * @params {date, time, datetime} type
 * */
export function formatTime(val, type) {
  if (!val) {
    return '--'
  } else {
    let time = new Date(val)
    let year = time.getFullYear()
    let month = time.getMonth() + 1 + ''
    let day = time.getDate() + ''
    let hour = time.getHours() + ''
    let minute = time.getMinutes() + ''
    let second = time.getSeconds() + ''
    month = month.length === 1 ? '0' + month : month
    day = day.length === 1 ? '0' + day : day
    hour = hour.length === 1 ? '0' + hour : hour
    minute = minute.length === 1 ? '0' + minute : minute
    second = second.length === 1 ? '0' + second : second
    if (type === 'date') {
      return '' + year + '-' + month + '-' + day
    } else if (type === 'time') {
      return '' + hour + ':' + minute + ':' + second
    } else if (type === 'hour') {
      return '' + hour + ':' + minute
    } else {
      return '' + year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
    }
  }
}
function isObject(obj) {
  return obj !== null && typeof obj === 'object'
}
// 判断两个值是否相等（宽松比较），即判断是否严格相等或是否具有相同的形状和类型
export function looseEqual(a, b) {
  if (a === b) { // 如果是绝对相等就直接返回true
    return true;
  }
  var isObjectA = isObject(a); // 检查a是否是对象
  var isObjectB = isObject(b); // 检查b是否是对象
  if (isObjectA && isObjectB) { // 如果两个均是对象
    try {
      var isArrayA = Array.isArray(a); // 检查a是否是数组
      var isArrayB = Array.isArray(b); // 检查b是否是数组
      if (isArrayA && isArrayB) { // 如果都是数组
        if (a.length === b.length) { // 如果长度相等
          return a.every(function (e, i) { // 用every和递归来比对a数组和b数组的每个元素，并返回
            return looseEqual(e, b[i])
          })
        }
        // 长度都不等直接返回false
        return false;
      } else if (a instanceof Date && b instanceof Date) { // 如果都是Date实例，则直接比较getTime值
        return a.getTime() === b.getTime()
      } else if (!isArrayA && !isArrayB) { // 对象的比较
        var keysA = Object.keys(a); // 获取a对象的key
        var keysB = Object.keys(b); // 获取b对象的key
        if (keysA.length === keysB.length) { // 如果keys相等
          return keysA.every(function (key) { // 用every和递归来比对a对象和b对象的每个元素值，并返回
            return looseEqual(a[key], b[key]);
          })
        }
        // 长度都不等直接返回false
        return false;
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  } else if (!isObjectA && !isObjectB) { // 如果都不是对象，则按字符串比较
    return String(a) === String(b)
  } else {
    return false
  }
}
/**
 * @description: 时间戳转为天时分秒
 * @param {*} date 时间戳
 * @param {*} num 需要展示多少个单位,最大为4，默认为4，天时分秒
 * @return {*} ??天??时??分??秒
 */
export function timestamp(date, num = 4) {
  //时间戳转化为天时分秒
  // 时间戳转化的总秒数
  let maxNum = num > 4 ? 4 : num
  let second = Math.floor(date / 1000);
  // 天数
  let day = Math.floor(second / 3600 / 24);
  // 小时
  let hr = Math.floor((second / 3600) % 24);
  // 分钟
  let min = Math.floor((second / 60) % 60);
  //秒
  let sec = Math.floor(second % 60);
  let arr = [day, hr, min, sec]
  let unit = ['天', '小时', '分钟', '秒']
  let data = ''
  for (let i = 0; i < maxNum; i++) {
    if (arr[i]) {
      data += arr[i] + unit[i]
    } else {
      data += ''
    }
  }
  return data
}
/**
 * @description: + - 解决精度缺失
 * @param {*} add 加数（被减数）
 * @param {*} reduce 被加数（被减数）
 * @param {*} s 是加减符号
 * @param {*} num 需要保留几位小数
 */
export function formatNum(add, reduce, s, num) {
  let m = Math.pow(10, num);
  let res = s == '+' ? (add * m + reduce * m) / m : (add * m - reduce * m) / m;
  return Math.round(res * m) / m;
}
/**
 * @description: + - 解决精度缺失
 * @param {*} num 数字
 * * @param {*} precision 需要保留几位小数
 */
export function toPrecision(num, precision) {
  if (precision === undefined) precision = 2
  return parseFloat(Math.round((num * Math.pow(10, precision)).toFixed(precision)) / Math.pow(10, precision))
}